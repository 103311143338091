<template>
  <div class="student-footer" v-if="isCurrentPage && isLoaded">
    <div class="footer d-flex text-light text-left footer-space">
      <div
        class="col-4"
        :style="{
          opacity:
            isExplorer
              ? '100%'
              : '20%',
        }"
      >
        <div
          class="d-flex align-items-center"
          style="column-gap: 10px; margin-bottom: 10px"
        >
          <img
            class="icon_img"
            src="~@/assets/search-big.png"
            alt=""
            srcset=""
          />
          <p class="mb-0 text-uppercase footer-header">Explorer</p>
        </div>
        <ul>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Search thesis
            inspiration
          </li>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Explore student
            thesis topics
          </li>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Professional guidance
            on thesis project
          </li>
        </ul>
      </div>
      <div
        class="col-4"
        :style="{ opacity: isRisingStar ? '100%' : '20%' }"
      >
        <div
          class="d-flex align-items-center"
          style="column-gap: 10px; margin-bottom: 10px"
        >
          <img
            class="icon_img"
            src="~@/assets/arrow-big.png"
            alt=""
            srcset=""
          />
          <p class="mb-0 text-uppercase footer-header">Rising Star</p>
        </div>
        <ul>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Unlock Excelerate
            Thesis Collaboration Matching
          </li>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Match with companies
            in your industry
          </li>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Upload thesis topic /
            project proposal
          </li>
        </ul>
      </div>
      <div
        class="col-4"
        :style="{ opacity: !isExplorer && !isRisingStar ? '100%' : '20%' }"
      >
        <div
          class="d-flex align-items-center"
          style="column-gap: 10px; margin-bottom: 10px"
        >
          <img class="icon_img" src="~@/assets/star-big.png" alt="" srcset="" />
          <p class="mb-0 text-uppercase footer-header">Talent</p>
        </div>
        <ul>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Unlock Excelerate
            Career Matching
          </li>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Triple thesis
            collaboration chances
          </li>
          <li>
            <i class="fa fa-check" aria-hidden="true"></i> Boost profile to
            stand out
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'StudentFooter',
  data() {
    return {
      userDetails: {},
    };
  },
  computed: {
    ...mapState(['user']),
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.userDetails = userInfo;
      }
      return true;
    },
    isCurrentPage() {
      return this.$route.path.includes('student-welcome') ||
        this.$route.path.includes('student-signup') || this.$route.path.includes('student-rising-star')  || this.$route.path.includes('student-talent')
        ? true
        : false;
    },
    isExplorer(){
      return this.$route.path.includes('student-welcome') || this.$route.path.includes('student-signup')
    },
    isRisingStar(){
      return this.$route.path.includes('student-rising-star')
    }
  },
};
</script>

<style scoped>
.footer {
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
    0% no-repeat padding-box;
}
.footer-space {
  padding: 50px 100px;
}
@media (max-width: 1250px) {
  .footer-space {
    /* padding: 50px 100px; */
    padding: 50px 0px !important;
  }
  .footer-header {
  font-size: 24px !important;
  font-weight: 600 !important;
}
}
ul {
  list-style: none;
  margin-left: 37px;
}
.footer-header {
  font-size: 38px;
  font-weight: 600;
}
.icon_img {
  width: 64px;
  height: 64px;
}
@media (max-width: 500px) {
  .student-footer {
    display: none;
  }
}
</style>
